import { t } from "../../i18n";
import { usersIcon } from "../icons";
import { Button } from "../Button";

import clsx from "clsx";
import { useExcalidrawAppState } from "../App";

import "./LiveCollaborationTrigger.scss";

const LiveCollaborationTrigger = ({
  isCollaborating,
  onSelect,
  ...rest
}: {
  isCollaborating: boolean;
  onSelect: () => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const appState = useExcalidrawAppState();

  return (
    <></>
    // <Button
    //   {...rest}
    //   className={clsx("collab-button", { active: isCollaborating })}
    //   type="button"
    //   onSelect={onSelect}
    //   style={{ position: "relative" }}
    //   title={t("labels.liveCollaboration")}
    // >
    //   {usersIcon}
    //   {appState.collaborators.size > 0 && (
    //     <div className="CollabButton-collaborators">
    //       {appState.collaborators.size}
    //     </div>
    //   )}
    // </Button>
  );
};

export default LiveCollaborationTrigger;
LiveCollaborationTrigger.displayName = "LiveCollaborationTrigger";
