import { SyncableExcalidrawElement } from ".";
import { MIME_TYPES } from "../../constants";
import { restoreElements } from "../../data/restore";
import { getSceneVersion } from "../../element";
import { ExcalidrawElement, FileId } from "../../element/types";
import { AppState, BinaryFileData, DataURL } from "../../types";
import { getSearchParam } from "../../utils";
import Portal from "../collab/Portal";
import { getResource, saveToRedis, uploadImageToSvc } from "./resource";

class FirebaseSceneVersionCache {
  private static cache = new WeakMap<SocketIOClient.Socket, number>();
  static get = (socket: SocketIOClient.Socket) => {
    return FirebaseSceneVersionCache.cache.get(socket);
  };
  static set = (
    socket: SocketIOClient.Socket,
    elements: readonly SyncableExcalidrawElement[],
  ) => {
    FirebaseSceneVersionCache.cache.set(socket, getSceneVersion(elements));
  };
}

export const isSavedToFirebase = (
  portal: Portal,
  elements: readonly ExcalidrawElement[],
): boolean => {
  if (portal.socket && portal.roomId && portal.roomKey) {
    const sceneVersion = getSceneVersion(elements);
    const persistedVersion = FirebaseSceneVersionCache.get(portal.socket);
    // console.log("in isSavedToFirebase", { sceneVersion, persistedVersion });
    if (persistedVersion === undefined && !sceneVersion) {
      return true;
    }
    return persistedVersion === sceneVersion;
  }
  // if no room exists, consider the room saved so that we don't unnecessarily
  // prevent unload (there's nothing we could do at that point anyway)
  return true;
};
export async function dataUrlToFile(
  dataUrl: string,
  fileName: string,
): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: "image/png" });
}

export const saveFilesToFirebase = async ({
  prefix,
  files,
  roomKey,
}: {
  prefix: string;
  files: Map<FileId, BinaryFileData>;
  roomKey: string;
}) => {
  const erroredFiles = new Map<FileId, true>();
  const savedFiles = new Map<FileId, true>();

  for (const [id, fileData] of files) {
    const file = await dataUrlToFile(fileData.dataURL, fileData.id);

    const publicUrl = await uploadImageToSvc(file, id);
    savedFiles.set(id, true);
  }

  return { savedFiles, erroredFiles };
};

export const saveToFirebase = async (
  portal: Portal,
  elements: readonly SyncableExcalidrawElement[],
  appState: AppState,
) => {
  const { roomId, roomKey, socket } = portal;

  if (!roomId || !roomKey || !socket || isSavedToFirebase(portal, elements)) {
    return false;
  }

  await saveToRedis(elements as unknown as ExcalidrawElement[]);
  FirebaseSceneVersionCache.set(socket, elements);

  return { reconciledElements: elements };
};

export const loadFromFirebase = async (
  roomId: string,
  roomKey: string,
  socket: SocketIOClient.Socket | null,
): Promise<readonly ExcalidrawElement[] | null> => {
  const elements = await getResource();
  if (!elements) {
    return null;
  }
  if (socket) {
    FirebaseSceneVersionCache.set(socket, elements);
  }

  return restoreElements(elements, null);
};

export const loadFilesFromFirebase = async (
  prefix: string,
  decryptionKey: string,
  filesIds: readonly FileId[],
) => {
  const loadedFiles: BinaryFileData[] = [];
  const erroredFiles = new Map<FileId, true>();
  const staticServiceUrl = getSearchParam("staticServiceUrl");

  await Promise.all(
    [...new Set(filesIds)].map(async (id) => {
      try {
        loadedFiles.push({
          mimeType: MIME_TYPES.binary,
          id,
          dataURL: `${staticServiceUrl}/smartboard/${id}` as DataURL,
          created: Date.now(),
          lastRetrieved: Date.now(),
        });
      } catch (error: any) {
        erroredFiles.set(id, true);
        console.error(error);
      }
    }),
  );

  return { loadedFiles, erroredFiles };
};
