import { ExcalidrawElement } from "../../element/types";
import { getSearchParam } from "../../utils";
import { clearLocalStorage, importFromLocalStorage } from "./localStorage";

export const uploadImageToSvc = async (file: File, imageId: string) => {
  try {
    const uploadImage = {
      operationName: "ExecSvcClient",
      variables: {
        operation: "fs.uploadDrawingServiceImage",
        args: `["${imageId}"]`,
      },
      query:
        "mutation ExecSvcClient($operation: String!, $args: String) {\n  execSvc(operation: $operation, args: $args) {\n    id\n    success\n    error\n    result\n    __typename\n  }\n}\n",
    };

    const uploadData = await fetch(process.env.REACT_APP_BACKEND_URL, {
      method: "POST",
      body: JSON.stringify(uploadImage),
      credentials: "include",
      headers: {
        // authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      keepalive: true,
    });

    // console.log("uploadData", uploadData.ok);
    // if(!token || !uploadData.ok) {
    //     throw new Error()
    // }
    const res = await uploadData.json();
    const { publicUrl, uploadUrl } = JSON.parse(
      res?.data?.execSvc?.result ?? "{}",
    );

    if (uploadUrl) {
      const uploaded = await fetch(uploadUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": !!file.type ? file.type : "application/octet-stream",
        },
      });

      // await cb(args);

      // const image = imageCache.get(imageId);

      // imageCache.set(imageId, {
      //     ...image,

      // })

      if (uploaded.ok) {
        return publicUrl;
      }
    }
  } catch (error) {
    console.error(error);
    throw new Error("Error uploading image");
  }
};

export const updateResource = async (elements: ExcalidrawElement[]) => {
  const isMemoryParam = getSearchParam("isMemory");
  const isMemory = isMemoryParam === "true";
  if (isMemory) {
    return;
  }
  const isAdminParam = getSearchParam("isAdmin");
  const isAdmin =
    typeof isAdminParam === "boolean" ? isAdminParam : isAdminParam === "true";
  const hasBackupOwnership = localStorage.getItem("backup_ownership");
  const canWrite = isAdmin || hasBackupOwnership;

  if (!canWrite) {
    return;
  }

  //   const { elements } = importFromLocalStorage();
  const resourceId = getSearchParam("resourceId");
  const roomId = getSearchParam("room");

  const smartboardState = JSON.stringify({
    elements,
    appState: {},
  });

  const headers = {
    type: "application/json",
  };
  const updateResource = {
    operationName: "ExecSvcClient",
    variables: {
      operation: "resx.updateResource",
      args: `["${resourceId}","flowos/blackboard/resx/Blackboard",{"smartboardState": ${smartboardState},"roomId": "${roomId}"}, true]`,
    },
    query:
      "mutation ExecSvcClient($operation: String!, $args: String) {\n  execSvc(operation: $operation, args: $args) {\n    id\n    success\n    error\n    result\n    __typename\n  }\n}\n",
  };

  const blob = new Blob([JSON.stringify(updateResource)], headers);

  try {
    navigator.sendBeacon(process.env.REACT_APP_BACKEND_URL, blob);
  } catch (error) {
    console.error("error", error);
  }

  clearLocalStorage();
};

// export const saveToRedis = async (elements: ExcalidrawElement[]) => {
//   const isMemoryParam = getSearchParam("isMemory");

//   const isMemory = isMemoryParam === "true";
//   console.log("saveToRedis: IsMemory", isMemory);
//   if (isMemory) {
//     return;
//   }

//   const resourceId = getSearchParam("resourceId");
//   const roomId = getSearchParam("room");

//   const smartboardState = JSON.stringify({
//     elements,
//     appState: {},
//   });

//   const headers = {
//     type: "application/json",
//   };

//   // console.log("saveToRedis: before saving", {
//   //   resourceId,
//   //   smartboardState,
//   //   roomId,
//   //   backendUrl: process.env.REACT_APP_BACKEND_URL,
//   // });

//   const updateResource = {
//     operationName: "ExecSvcClient",
//     variables: {
//       operation: "resx.saveSmartboardToRedis",
//       args: `["${resourceId}",{"smartboardState": ${smartboardState},"roomId": "${roomId}"}]`,
//     },
//     query:
//       "mutation ExecSvcClient($operation: String!, $args: String) {\n  execSvc(operation: $operation, args: $args) {\n    id\n    success\n    error\n    result\n    __typename\n  }\n}\n",
//   };

//   const blob = new Blob([JSON.stringify(updateResource)], headers);

//   try {
//     navigator.sendBeacon(process.env.REACT_APP_BACKEND_URL, blob);
//   } catch (error) {
//     console.error("error", error);
//   }
// };

export const getResource = async () => {
  const resourceId = getSearchParam("resourceId");
  const isMemoryParam = getSearchParam("isMemory");
  const isMemory = isMemoryParam === "true";

  const query = {
    operationName: "ExecSvcClient",
    variables: {
      operation: "resx.getSmartboard",
      args: `["${resourceId}", ${isMemory}]`,
    },
    query:
      "mutation ExecSvcClient($operation: String!, $args: String) {\n  execSvc(operation: $operation, args: $args) {\n    id\n    success\n    error\n    result\n    __typename\n  }\n}\n",
  };

  const resourceData = await fetch(process.env.REACT_APP_BACKEND_URL, {
    method: "POST",
    body: JSON.stringify(query),
    credentials: "include",
    headers: {
      // authorization: `Bearer ${token}`,
      "content-type": "application/json",
    },
    keepalive: true,
  });

  try {
    const jsonData = await resourceData.json();
    // console.log("jsonData", jsonData);

    if (jsonData?.data?.execSvc?.result) {
      const resourceContent = JSON.parse(jsonData?.data?.execSvc?.result)
        ?.smartboardState?.elements;
      // console.log("resourceContent", resourceContent);
      return resourceContent ?? null;
    }
  } catch (error) {
    console.error(error);
  }
};

export const saveToRedis = async (elements: ExcalidrawElement[]) => {
  const isMemoryParam = getSearchParam("isMemory");

  const isMemory = isMemoryParam === "true";
  console.log("saveToRedis: IsMemory", isMemory);
  if (isMemory) {
    return;
  }

  const resourceId = getSearchParam("resourceId");
  const roomId = getSearchParam("room");

  const smartboardState = {
    elements,
    appState: {},
  };

  const headers = {
    type: "application/json",
  };

  // console.log("saveToRedis: before saving", {
  //   resourceId,
  //   smartboardState,
  //   roomId,
  //   backendUrl: process.env.REACT_APP_BACKEND_URL,
  // });

  // const updateResource = {
  //   operationName: "ExecSvcClient",
  //   variables: {
  //     operation: "resx.saveSmartboardToRedis",
  //     args: `["${resourceId}",{"smartboardState": ${smartboardState},"roomId": "${roomId}"}]`,
  //   },
  //   query:
  //     "mutation ExecSvcClient($operation: String!, $args: String) {\n  execSvc(operation: $operation, args: $args) {\n    id\n    success\n    error\n    result\n    __typename\n  }\n}\n",
  // };

  const body = {
    smartboardState: smartboardState,
    roomId: roomId,
  };

  // const blob = new Blob([JSON.stringify(body)], headers);

  try {
    // navigator.sendBeacon(
    //   `${process.env.REACT_APP_WHITEBOARD_SYNC_SERVER_URL}/whiteboard/${resourceId}`,
    //   blob,
    // );

    await fetch(
      `${process.env.REACT_APP_WHITEBOARD_SYNC_SERVER_URL}/api/${resourceId}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        // credentials: "include",
        headers: {
          // authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        keepalive: true,
      },
    );
  } catch (error) {
    console.error("error", error);
  }
};
