import { VERSIONS } from "../constants";
import { t } from "../i18n";
import { AppState, ExcalidrawProps } from "../types";
import { getSearchParam } from "../utils";

const LibraryMenuBrowseButton = ({
  theme,
  id,
  libraryReturnUrl,
}: {
  libraryReturnUrl: ExcalidrawProps["libraryReturnUrl"];
  theme: AppState["theme"];
  id: string;
}) => {
  const parentLocation = getSearchParam("parentLocation");

  const referrer =
    parentLocation ||
    libraryReturnUrl ||
    window.location.origin + window.location.pathname;
  return <></>;

  // return (
  //   <a
  //     className="library-menu-browse-button"
  //     href={`${process.env.REACT_APP_LIBRARY_URL}?target=${
  //       window.name || "_blank"
  //     }&referrer=${referrer}&useHash=true&token=${id}&theme=${theme}&version=${
  //       VERSIONS.excalidrawLibrary
  //     }`}
  //     target="_excalidraw_libraries"
  //   >
  //     {t("labels.libraries")}
  //   </a>
  // );
};

export default LibraryMenuBrowseButton;
